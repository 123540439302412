"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.apiFetch = exports.externalFetch = exports.AbortableFetch = exports.defaultOptions = void 0;
exports.defaultOptions = {
    expectJson: true,
    expectText: false,
    mapErrorTo: { shouldMap: false, targetValue: undefined },
    init: undefined,
};
const AbortControllersList = {};
const AbortableFetch = (url, path, options, controllerKey) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    try {
        let isAborted = false;
        if (!window.AbortController) {
            const response = yield (0, exports.apiFetch)(url, path, options).catch((e) => {
                throw e;
            });
            return { response, isAborted };
        }
        if (AbortControllersList[controllerKey]) {
            AbortControllersList[controllerKey].abort();
            isAborted = true;
        }
        AbortControllersList[controllerKey] = new AbortController();
        const _options = Object.assign(Object.assign({}, options), { init: Object.assign(Object.assign({}, options === null || options === void 0 ? void 0 : options.init), { signal: AbortControllersList[controllerKey].signal }) });
        const response = yield (0, exports.apiFetch)(url, path, _options).catch((e) => {
            throw e;
        });
        delete AbortControllersList[controllerKey];
        return { response, isAborted };
    }
    catch (e) {
        if (e.name === 'AbortError' || ((_b = (_a = AbortControllersList[controllerKey]) === null || _a === void 0 ? void 0 : _a.signal) === null || _b === void 0 ? void 0 : _b.aborted)) {
            console.warn(`${url} call was aborted`, e);
            return { response: undefined, isAborted: true };
        }
        else {
            throw e;
        }
    }
});
exports.AbortableFetch = AbortableFetch;
const externalFetch = (url, options = exports.defaultOptions) => __awaiter(void 0, void 0, void 0, function* () {
    const mergedOptions = Object.assign(Object.assign({}, exports.defaultOptions), options);
    return doFetch(url, mergedOptions);
});
exports.externalFetch = externalFetch;
const apiFetch = (url, path, options = exports.defaultOptions) => __awaiter(void 0, void 0, void 0, function* () {
    var _c, _d, _e;
    const mergedOptions = Object.assign(Object.assign({}, exports.defaultOptions), options);
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken && !((_d = (_c = mergedOptions.init) === null || _c === void 0 ? void 0 : _c.headers) === null || _d === void 0 ? void 0 : _d.Authorization)) {
        mergedOptions.init = Object.assign(Object.assign({}, mergedOptions.init), { headers: Object.assign(Object.assign({}, (_e = mergedOptions.init) === null || _e === void 0 ? void 0 : _e.headers), { ['Authorization']: `Bearer ${accessToken}` }) });
    }
    return doFetch(`${url}${path}`, mergedOptions);
});
exports.apiFetch = apiFetch;
const doFetch = (url, options = exports.defaultOptions) => __awaiter(void 0, void 0, void 0, function* () {
    var _f, _g;
    let response;
    try {
        response = yield fetch(url, options.init);
    }
    catch (err) {
        console.error('fetch has failed', { err, url });
        return;
    }
    if (!(response === null || response === void 0 ? void 0 : response.ok)) {
        if (options.onError) {
            yield options.onError(response);
            return undefined;
        }
        if ((_f = options.mapErrorTo) === null || _f === void 0 ? void 0 : _f.shouldMap) {
            return (_g = options.mapErrorTo) === null || _g === void 0 ? void 0 : _g.targetValue;
        }
    }
    if ((response === null || response === void 0 ? void 0 : response.status) === 204) {
        return;
    }
    if (options.expectText) {
        return response === null || response === void 0 ? void 0 : response.text();
    }
    else if (options.expectJson) {
        try {
            return response === null || response === void 0 ? void 0 : response.json();
        }
        catch (error1) {
            let text;
            try {
                text = yield response.text();
            }
            catch (error2) {
            }
            const message = `Error parsing JSON for ${url}. ${error1}. Text response was: ${text || 'not parseable'}`;
            if (options.onError) {
                options.onError(response);
            }
            else {
                throw new Error(message);
            }
        }
    }
    else {
        return response;
    }
});
